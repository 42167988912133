<template lang="pug">
.home-mobile-quick-buy(
  v-if='isHomeQuickBuyItemsLoaded && !fetchingError'
  v-bind:class="hasRibbon() ? 'with-padding' : ''"
)
  BlRibbonAv(v-if="hasRibbon()").ribbon--top-right.ribbon--expressive
    BlTextAv(
      variation="caption-12"
      color="inverse"
      weight="bold"
    ) {{ ribbonText }}
  HeaderSection
  CategoryQuickBuySection(
    v-for='item in homeQuickBuyItems' :key='item.id'
    :category='item'
  )

</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlRibbonAv from '@bukalapak/bazaar-mweb/dist/components/BlRibbonAv';
import HeaderSection from './components/HeaderSection.vue';
import CategoryQuickBuySection from './components/CategoryQuickBuySection.vue';
import { HOME_QUICKBUY_NEO_CONFIG } from './constants'

export default {
  name: 'ListWhitelistCategoriesSellers',
  components: {
    BlTextAv,
    HeaderSection,
    CategoryQuickBuySection,
    BlRibbonAv,
  },
  computed: {
    isHomeQuickBuyItemsLoaded() {
      return this.$store.state.homeMobileQuickBuy.isHomeQuickBuyItemsLoaded;
    },
    homeQuickBuyItems() {
      return this.$store.state.homeMobileQuickBuy.homeQuickBuyItems;
    },
    fetchingError() {
      return this.$store.state.homeMobileQuickBuy.fetchingError;
    },
    ribbonText() {
      const homeQuickBuyConfig = this.$store.state.homeMobileQuickBuy.neoConfigsMap[HOME_QUICKBUY_NEO_CONFIG];
      return homeQuickBuyConfig && homeQuickBuyConfig.ribbon_text ? homeQuickBuyConfig.ribbon_text : '';
    }
  },
  methods : {
    hasRibbon() {
      return this.ribbonText && this.ribbonText !== '';
    },
  },
};
</script>

<style lang="scss">
.home-mobile-quick-buy {
  background: linear-gradient(68deg, rgba(1, 168, 196, 0.15) -1.82%, rgba(251, 254, 127, 0.2) 106.59%);
  padding: 12px 0px 12px 0px; // changed from 32px to 12px adjust with design
  position: relative;
  margin-top: -13px;
}

.with-padding {
  padding-top: 36px;
}

.ribbon--top-right {
  top: 0;
  right: 0;
  padding: 6px 8px 6px 16px;
  border-radius: 0 0 0 20px;
}

.ribbon--expressive {
  background-color: #3838a8;
}
</style>
