<template lang="pug">
.qb-home-main-header-wrapper
  .qb-home-header-section
    .header-left
      BlTextAv(
        variation="heading-24"
        color="secondary"
      ) Beli Cepat⚡
    a.header-right(href="/beli-cepat")
      BlTextAv(
        variation="body-14"
        weight="bold"
        color="link-01"
      ) Lihat Semua
      IcoChevronRight(
        color="#2B4AC7"
      )
  .subheader-section
    BlTextAv(
      variation="caption-10"
      color="subdued"
    ) Nikmati virtual Product termurah setiap hari, tanpa menunggu promo!
</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import IcoChevronRight from '@bukalapak/bazaar-visual-v1/dist/components/IcoChevronRightMinor';

export default {
  name: 'HeaderSection',
  components: {
    IcoChevronRight,
    BlTextAv,
  },
};
</script>

<style lang="scss">
.qb-home-header-section {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
}

.qb-home-header-section .header-right {
  display: flex;
  align-self: center;
}

.qb-home-main-header-wrapper .subheader-section {
  margin-top: 8px;
  padding: 0px 16px;
}

.qb-home-main-header-wrapper {
  margin-bottom: 2px;
}
</style>
