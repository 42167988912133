<template lang="pug">
a.qb-home-card-item(v-bind:href='getQuickBuyCatalogUrl')
  BlProductImageAv.extra-super-small(
    :image-url="iconUrl"
    :image-alt="name"
  )
  .card-text
    BlTextAv(
      variation="caption-10"
      :ellipsis="2"
    ) {{ name }}
  .card-footer-ribbon(v-if='hasPromotionalTag()')
    BlTextAv.card-ribbon-text(
      color="inverse"
      weight="bold"
    ) {{ promotionalTag }}
</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlProductImageAv from '@bukalapak/bazaar-mweb/dist/components/BlProductImageAv';

export default {
  name: 'CardItems',
  components: {
    BlTextAv,
    BlProductImageAv,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    iconUrl: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    promotionalTag: {
      type: String,
      default: '',
    },
  },
  computed: {
    getQuickBuyCatalogUrl() {
      return `/beli-cepat/${this.slug}`;
    },
  },
  methods: {
    hasPromotionalTag() {
      if (this.promotionalTag && this.promotionalTag !== '') return true;
      return false;
    },
  },
};
</script>

<style lang="scss">
.qb-home-card-item {
  background-color: #fff;
  min-width: 76px;
  border-radius: 8px;
  min-height: 94px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 4px;
  position: relative;
  overflow: hidden;
}

.qb-home-card-item .card-image {
  width: 40px;
  height: 40px;
  background-color: aliceblue;
}

.qb-home-card-item .card-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  /* Handles multiple line */
  width: 71px;
  text-align: center;
}

.qb-home-card-item .card-footer-ribbon {
  position: absolute;
  z-index: 99;
  padding-top: 3px;
  padding-bottom: 3px;
  bottom: 0;
  background-color: #e31e52;

  /* Handle fill ribbon to its parent */
  width: 100%;
  text-align: center;
}

.card-ribbon-text {
  margin-left: 3px;
  margin-right: 3px;
  font-size: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.extra-super-small {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  width: 40px;
  height: 40px;
}

.extra-super-small .bl-product-image__inner {
  object-fit: contain;
}

.qb-home-card-item .card-footer-ribbon {
  position: absolute;
  z-index: 99;
  padding-top: 3px;
  padding-bottom: 3px;
  bottom: 0;
  background-color: #e31e52;

  /* Handle fill ribbon to its parent */
  width: 100%;
  text-align: center;
}

.card-ribbon-text {
  font-size: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
