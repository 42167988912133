<template lang="pug">
.category-quick-buy-section-wrapper
  .title-section
    BlTextAv(
      variation="body-14"
      weight="bold"
      color="secondary"
    ) {{category.promotional_tag}}
  .catalog-section-wrapper
    .tweak-padding
    CardItems(
      v-for="catalog in category.catalogs" :key="catalog.id"
      :slug="catalog.slug"
      :icon-url="catalog.icon_url"
      :name="catalog.name"
      :promotional-tag="catalog.promotional_tag"
    )

</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import CardItems from './CardItems.vue';

export default {
  name: 'CategoryQuickBuySection',
  components: {
    BlTextAv,
    CardItems,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.category-quick-buy-section-wrapper .title-section {
  padding-left: 16px;
}
.category-quick-buy-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

.category-quick-buy-section-wrapper .catalog-section-wrapper {
  display: flex;
  gap: 4px;
  overflow: auto;
  padding-right: 16px;
}

.category-quick-buy-section-wrapper .catalog-section-wrapper::-webkit-scrollbar {
  display: none;
}
.tweak-padding {
  /* this is how to make the padding on initial render, but when card scrolling, the padding dissapear */
  margin-left: 16px;
}
</style>
