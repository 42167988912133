const namespaced = true;

export default {
  namespaced,
  state: () => ({
    homeQuickBuyItems: [],
    isHomeQuickBuyItemsLoaded: false,
    fetchingError: false,
    neoConfigsMap: null,
  }),
  mutations: {
    setHomeQuickBuyItems(state, { homeQuickBuyItems }) {
      state.homeQuickBuyItems = homeQuickBuyItems;
    },
    setHomeQuickBuyItemsLoaded(state, isLoaded) {
      state.isHomeQuickBuyItemsLoaded = isLoaded;
    },
    setFetchingError(state, fetchingError) {
      state.fetchingError = fetchingError;
    },
    setNeoConfigsMap(state, { neoConfigsMap }) {
      state.neoConfigsMap = neoConfigsMap;
    },
    setInitialState(state, { homeQuickBuyItems, isHomeQuickBuyItemsLoaded, fetchingError, neoConfigsMap }) {
      state.homeQuickBuyItems = homeQuickBuyItems;
      state.isHomeQuickBuyItemsLoaded = isHomeQuickBuyItemsLoaded;
      state.fetchingError = fetchingError;
      state.neoConfigsMap = neoConfigsMap;
    },
  },
};
