const INITIAL_APP_DATA = '__HOME_MOBILE_QUICK_BUY_STATE__';
const MOUNT_ID = 'homepage-mobile-quick-buy';
const MAX_QUICKBUY_HOMEPAGE_CATEGORY = 2;
const HOME_QUICKBUY_NEO_CONFIG = 'digimon-home-quick-buy';

module.exports = {
  INITIAL_APP_DATA,
  MOUNT_ID,
  MAX_QUICKBUY_HOMEPAGE_CATEGORY,
  HOME_QUICKBUY_NEO_CONFIG,
};
