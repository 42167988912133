import homeMobileQuickBuyModule from './modules/home-mobile-quick-buy';

const createStore = ({ Vuex }) =>
  new Vuex.Store({
    modules: {
      homeMobileQuickBuy: homeMobileQuickBuyModule,
    },
  });

export default createStore;
